header {
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 14px;
  flex: 0;
  box-shadow: 0 3px 10px -4px #000000;
  position: relative;
  height: 73px;

  img {
    width: 200px;
  }

  h1 {
    color: white;
    font-size: 18px;
    padding: 0 1px;
    margin: 0;
  }
}

.content-container {
  display: flex;
  flex: 1 1 0;
  min-height: 0;
  background: #2d2d2d;

  nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  nav div {
    font-family: "open sans condensed";
    width: 190px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    border-right: 1px solid black;

    * {
      color: white;
      text-decoration: none;
      padding: 12px;
      border-bottom: 1px solid #000;
      width: 100%;
      font-size: 18px;

      &:hover {
        background-color: #ffd500;
        color: black;
      }

      &.active {
        background-color: #ffd500;
        color: black;
      }
    }
  }

  .outlet {
    height: 100%;
    background: white;
    flex: 1 1 auto;
  }
}

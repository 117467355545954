html {
  overflow: hidden;
}

body {
  margin: 0;
  background: #efefef;
  font-family: "open sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

section {
  padding: 20px;
  height: 100%;
}

h1,h2,h3,h4,h5 {
  text-transform: uppercase;
  font-family: "open sans condensed";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

li {
  padding: 10px;
}

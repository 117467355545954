#gsavi {
  display: flex;
  height: 100%;
  overflow: auto;

  div {
    flex: 1 1 auto;
  }

  img {
    width: 650px;
    margin: 5px;
  }
}

#overview {
  display: flex;

  .map-container {
    flex: 1 1 0;
    border-radius: 6px;
    overflow: hidden;
  }

  .key {
    margin-right: 10px;
  }
}

.vessel-map-img {
  height: 12px;
}


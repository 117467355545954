.outer-box {
  width: 100%;
  height: 100vh;
  background: #000;

  display: flex;
  align-items: center;
  justify-content: center;

  .info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 20%;
  }
}